import { DirectiveBinding } from "vue";
interface IResize extends Element {
  _onResize: any;
}
export const Resize = {
  mounted(el: IResize, binding: DirectiveBinding) {
    const handler = binding.value;
    const options = {
      passive: !binding.modifiers?.active,
    };
    window.addEventListener("resize", handler, options);
    el._onResize = {
      handler,
      options,
    };
    if (!binding.modifiers?.quiet) {
      handler();
    }
  },
  unmounted(el: IResize) {
    if (!el._onResize) return;
    const { handler, options } = el._onResize;
    window.removeEventListener("resize", handler, options);
    delete el._onResize;
  },
};
export default Resize;
