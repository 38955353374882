import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import pinia from "./store";
import "./assets/base.css";

// import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import { Quill } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueLazyload from "vue-lazyload";

import directive from "./directive/index";
import useLogin from "./store/login/login";
import "uno.css";
import "quill-image-resize-module/image-resize.min.js";
import { ImageDrop } from "@/utils/ImageDrop";
// import BlotFormatter from "quill-blot-formatter";
// import ImageResize from "quill-image-resize-module";
import { ImageExtend } from "quill-image-extend-module";
import AdjustableImgBlot from "./components/dto-quill/src/AdjustableImgBlot";
import Video from "./components/dto-quill/src/videoBlot";

import "highlight.js/styles/monokai-sublime.css";
import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";

Quill.register("modules/ImageExtend", ImageExtend);
// Quill.register("modules/blotFormatter", BlotFormatter); // 编译不会报错，打包运行会报错 Super expression must either be null or a function, not undefined
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("formats/adjustImg", AdjustableImgBlot);
Quill.register("formats/video", Video);
// Quill.register("modules/imageResize", ImageResize); // 报错 "default" is not exported

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(directive);
app.use(hljsVuePlugin);
// 页面刷新时更新pinia必须的状态(如token)

// app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(VueLazyload);
app.use(pinia).mount("#app");
useLogin().loadLocalLogin();
