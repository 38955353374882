import { defineStore } from "pinia";
const msgbox = defineStore("msgbox", {
  state: () => ({
    //访问被禁止,403
    authfail:false,
    //需要重新登录,401
    needlogin:false
  }),
})

export default msgbox