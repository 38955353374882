/**
 * axios请求封装
 */
import axios from "axios";
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { ResultCode } from "@/types/ResultCode";
import { ElLoading } from "element-plus";
import router from "@/router";
import msgbox from "@/store/main/msgbox";
import useLogin from "@/store/login/login";
interface IRequestConfig extends AxiosRequestConfig {
  // interceptors?: IRequestInterceptors;
  showLoading?: boolean;
}
// interface IRequestInterceptors {
//   requestInterceptor?: (config: any) => any;
//   requestInterceptorCatch?: (err: any) => any;
//   responseInterceptor?: (config: any) => any;
//   responseInterceptorCatch?: (err: any) => any;
// }
const DEFAULT_LOADING = true;
const failReturn = (e: any): any => ({
  result: "fail",
  msg: e.message,
});
let needLoadingRequestCount = 0;
class httpRequest {
  loading?: any;
  instance: AxiosInstance;
  // interceptors?: IRequestInterceptors;
  showLoading?: boolean;

  /**
   * 增加请求拦截,可以通过removeOnRequest移除
   * @param onSent 请求之前的回调 参数为什么不能是AxiosRequestConfig?
   * @param onError 请求出错的回调
   * @returns 拦截id
   */
  onRequest(onSent?: (cfg: any) => any, onError?: (err: AxiosError) => void) {
    return this.instance.interceptors.request.use(onSent, onError);
  }
  removeOnRequest(id: number) {
    this.instance.interceptors.request.eject(id);
  }
  /**
   * 增加响应拦截,可以通过removeOnResp移除
   * @param onSuccess 响应成功拦截
   * @param onError 错误拦截
   * @returns 拦截id
   */
  onResp(onSuccess?: (resp: any) => any, onError?: (err: any) => void) {
    return this.instance.interceptors.response.use(onSuccess, onError);
  }
  removeOnResp(id: number) {
    this.instance.interceptors.response.eject(id);
  }

  constructor(config: IRequestConfig) {
    console.log(config);
    this.instance = axios.create(config);
    // this.interceptors = config.interceptors;
    this.showLoading = config.showLoading ?? DEFAULT_LOADING;
    // config中取出对应实例的拦截器
    // this.instance.interceptors.request.use(this.interceptors?.requestInterceptor, this.interceptors?.requestInterceptorCatch),
    //   this.instance.interceptors.response.use(this.interceptors?.responseInterceptor, this.interceptors?.responseInterceptorCatch);
    // 添加所有实例都有的拦截器
    this.onRequest(
      config => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        console.log("请求拦截成功1");
        if (this.showLoading && needLoadingRequestCount === 0) {
          this.loading = ElLoading.service({
            lock: true,
            text: "正在请求数据...",
            background: "rgba(0, 0, 0, .5)",
          });
        }
        needLoadingRequestCount++;

        return config;
      },
      err => {
        console.log("请求拦截失败1");
        return err;
      }
    );
    this.onResp(
      res => {
        needLoadingRequestCount--;
        needLoadingRequestCount = Math.max(needLoadingRequestCount, 0);
        console.log("--", needLoadingRequestCount);
        if (needLoadingRequestCount === 0) {
          this.loading?.close();
        }
        return res;
      },
      err => {
        needLoadingRequestCount--;
        needLoadingRequestCount = Math.max(needLoadingRequestCount, 0);
        console.log("--", needLoadingRequestCount);
        if (needLoadingRequestCount === 0) {
          this.loading?.close();
        }
        if (err.response.status === 404) {
          console.log("404的错误~");
        }
        //401表示未登录/登录失效
        else if (err.response.status == 401) {
          // msgbox().needlogin = true;
          let redirectUrl = "";

          if (useLogin().unAuthorizationFlag) {
            useLogin().unAuthorizationFlag = false;
            useLogin().authorization = false;

            router.push({
              name: "login",
            });
          }
        }
        //权限限制
        else if (err.response && err.response.status == 403) {
          ElMessage({
            type: "warning",
            message: "当前角色行为被禁止",
          });
          msgbox().authfail = true;
        }
        return err;
      }
    );
  }
  async get3(arg: { url: string; params?: any; responseType?: any }) {
    try {
      let ret = await this.instance.get(arg.url, {
        params: arg.params,
        responseType: arg.responseType,
      });
      return ret;
    } catch (e: any) {
      console.log(e);
      return failReturn(e);
    }
  }
  async get<TResult>(arg: { url: string; params?: any }): Promise<TResult> {
    try {
      let ret = await this.instance.get<TResult>(arg.url, {
        params: arg.params,
      });
      return this.proccess_resp(ret) as TResult;
    } catch (e: any) {
      console.log(e);
      return failReturn(e);
    }
  }
  async get2<TArg, TResult>(arg: { url: string; params: TArg }): Promise<TResult> {
    try {
      let ret = await this.instance.get<TResult>(arg.url, {
        params: arg.params,
      });
      return this.proccess_resp(ret) as TResult;
    } catch (e: any) {
      console.log(e);
      if (axios.isAxiosError(e)) {
        return failReturn(e);
      } else {
        //???
        return failReturn(e);
      }
    }
  }

  async post<T>(arg: { url: string; data: any; config?: AxiosRequestConfig }): Promise<T> {
    try {
      let ret = await this.instance.post<T>(arg.url, arg.data, arg.config);
      return this.proccess_resp(ret) as T;
    } catch (e: any) {
      console.log(e);
      if (axios.isAxiosError(e)) {
        return failReturn(e);
      } else {
        //???
        return failReturn(e);
      }
    }
  }
  async put<T>(arg: { url: string; data?: any; config?: AxiosRequestConfig }): Promise<T> {
    try {
      let ret = await this.instance.put<T>(arg.url, arg.data, arg.config);
      return this.proccess_resp(ret) as T;
    } catch (e: any) {
      console.log(e);
      if (axios.isAxiosError(e)) {
        return failReturn(e);
      } else {
        //???
        return failReturn(e);
      }
    }
  }
  async delete<TResult>(arg: { url: string; params?: any }): Promise<TResult> {
    try {
      let ret = await this.instance.delete<TResult>(arg.url, {
        params: arg.params,
      });
      return this.proccess_resp(ret) as TResult;
    } catch (e: any) {
      console.log(e);
      return failReturn(e);
    }
  }
  proccess_resp<T>(ret: AxiosResponse<T, any>) {
    console.log(ret);
    if (ret.status == 200) {
      return ret.data;
    } else if (ret.status >= 400) {
      ElMessage({
        type: "error",
        message: ret.statusText,
      });
    } else if (ret.status >= 500) {
      ElMessage({
        type: "error",
        message: ResultCode.ToString("err_serve"),
      });
    }
    return ret.data;
  }
}

export default httpRequest;
