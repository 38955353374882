import { createRouter, createWebHistory, createWebHashHistory, useRouter } from "vue-router";
import useLogin from "@/store/login/login";
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: "/main/summary",
    },
    {
      path: "/index",
      name: "index",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/index.vue"),
      beforeEnter: (to, from) => {
        if (from.name) {
          if (!useLogin().authorization) {
            useLogin().redirect_url = from.name as string;
          }
        }
      },
    },
    {
      path: "/main",
      name: "main",
      component: () => import("@/views/main/main.vue"),
      children: [
        {
          path: "/main/summary",
          name: "summary",
          component: () => import("@/views/main/summary/summary.vue"),
        },
        {
          path: "/main/school",
          name: "school",
          component: () => import("@/views/main/school/school.vue"),
        },
        {
          path: "/main/manage/schoolBasicInfo",
          name: "schoolBasicInfo",
          component: () => import("@/views/main/manage/school-basic-info/school-basic-info.vue"),
        },
        {
          path: "/main/manage/authManage",
          name: "authManage",
          component: () => import("@/views/main/manage/auth-manage/auth-manage.vue"),
        },
        {
          path: "/main/manage/instructBrowse",
          name: "instructBrowse",
          component: () => import("@/views/main/manage/instruct-browse/instruct-browse.vue"),
        },
        {
          path: "/main/manage/teachingMaterials/index",
          name: "resource",
          component: () => import("@/views/main/manage/teaching-materials/index.vue"),
        },
        {
          path: "/main/manage/teachingMaterials/courseBrowse",
          name: "courseBrowse",
          component: () => import("@/views/main/manage/teaching-materials/course-browse/school-course-browse.vue"),
        },
        {
          path: "/main/manage/teachingMaterials/examBrowse",
          name: "examBrowse",
          component: () => import("@/views/main/manage/teaching-materials/exam-browse/school-exam-browse.vue"),
        },
        {
          path: "/main/manage/teachingMaterials/questionBrowse",
          name: "questionBrowse",
          component: () => import("@/views/main/manage/teaching-materials/question-browse/school-question-browse.vue"),
        },
        // 管理-组织管理
        {
          path: "/main/manage/orgManage/classManage",
          name: "classManage",
          component: () => import("@/views/main/manage/org-manage/class-manage.vue"),
        },
        {
          path: "/main/manage/orgManage/interestGroup",
          name: "interestGroup",
          component: () => import("@/views/main/manage/org-manage/interest-group.vue"),
        },
        {
          path: "/main/manage/orgManage/teacherManage",
          name: "teacherManage",
          component: () => import("@/views/main/manage/org-manage/teacher-manage.vue"),
        },
        {
          path: "/main/classroom/classroom",
          name: "classroom",
          component: () => import("@/views/main/classroom/classroom.vue"),
        },
        // 团队
        {
          path: "/main/coteam/coteam",
          name: "coteam",
          component: () => import("@/views/main/coteam/coteam.vue"),
        },
        // 我的班级
        {
          path: "/main/myclass/myclass",
          name: "myclass",
          component: () => import("@/views/main/myclass/myclass.vue"),
        },
        // 我的兴趣小组
        {
          path: "/main/my-interest-group/my-interest-group",
          name: "myInterestGroup",
          component: () => import("@/views/main/my-interest-group/my-interest-group.vue"),
        },
        // 我的-课程
        {
          path: "/main/manage/mineCourseBrowse",
          name: "mineCourseBrowse",
          component: () => import("@/views/main/manage/teaching-materials/course-browse/mine-course-browse.vue"),
        },
        // 我的-题库浏览
        {
          path: "/main/manage/mineQuestionBrowse",
          name: "mineQuestionBrowse",
          component: () => import("@/views/main/manage/teaching-materials/question-browse/mine-question-browse.vue"),
        },
        // 我的-考卷浏览
        {
          path: "/main/manage/mineExamBrowse",
          name: "mineExamBrowse",
          component: () => import("@/views/main/manage/teaching-materials/exam-browse/exam-browse.vue"),
        },
      ],
    },
    {
      path: "/preview",
      name: "preview",
      component: () => import("@/components/preview/preview.vue"),
    },
    {
      path: "/paperPreview",
      name: "paperPreview",
      component: () => import("@/views/main/manage/teaching-materials/exam-browse/cpns/paper-preview.vue"),
    },
    {
      path: "/browseSubmit",
      name: "browseSubmit",
      component: () => import("@/views/main/classroom/cpns/browse-submit.vue"),
    },
    {
      path: "/paperInspection",
      name: "paperInspection",
      component: () => import("@/views/main/classroom/cpns/paper-inspection.vue"),
    },
  ],
});

// 导航守卫
router.beforeEach((to, from) => {
  console.log(to, from);
  if (to.path !== "/login") {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log(111);
      useLogin().authorization = false;
      return "/login";
    }
  }
});
export default router;
