import { defineStore } from "pinia";
import http from "@/service/http";
import { IDTO_Login, IDTO_LoginResult, IDTO_RegSchoolCreate, IMsg_Data, IMsg_Items, IStatistics } from "@/types/apiType";
import { ResultCode } from "@/types/ResultCode";
import { ISchoolInfo } from "@/views/login/types/login";
import { menuConfig_admin, menuConfig_user } from "@/components/nav-menu/src/config/menuConfig";
const useLogin = defineStore("useLogin", {
  state: () => ({
    userId: 0,
    loginStatus: "",
    loginInfo: {} as any,
    token: "",
    imgFiles: [] as string[],
    schoolInfo: {} as ISchoolInfo,
    city: [] as any[],
    USERTYPE_ADMIN: "admin",
    USERTYPE_USER: "user",
    unAuthorizationFlag: true, // 401内部逻辑执行一次flag
    authorization: true, // token是否授权
    redirect_url: "",
  }),
  getters: {
    userTypeMenu(state) {
      switch (state.loginInfo.userType) {
        case "admin": {
          return menuConfig_admin;
        }
        case "user": {
          return menuConfig_user;
        }
        default: {
          return menuConfig_admin;
        }
      }
    },
    isAdmin(state) {
      return state.loginInfo.userType === state.USERTYPE_ADMIN;
    },
  },
  actions: {
    setLoginInfo(info: any) {
      this.loginInfo = info;
    },
    setUserId(userId: number) {
      this.userId = userId;
    },
    setLoginStatus(status: string) {
      this.loginStatus = status;
    },
    setToken(token: string) {
      this.token = token;
    },
    setSchoolInfo(info: ISchoolInfo) {
      this.schoolInfo = info;
    },
    setImgFiles(file1: string, file2: string) {
      this.imgFiles = [file1, file2];
    },
    // 退出登录
    logOut() {
      this.setLoginInfo({});
      this.setUserId(0);
      this.setLoginStatus("");
      this.setToken("");
      this.setSchoolInfo({} as ISchoolInfo);

      localStorage.removeItem("token");
      localStorage.removeItem("loginInfo");
    },
    // 页面刷新的时候更新必要的store状态
    loadLocalLogin() {
      const token = localStorage.getItem("token");
      const loginInfo = JSON.parse(localStorage.getItem("loginInfo") as string);
      if (token) {
        this.setToken(token);
        this.getRegInfo(token);
      }
      if (loginInfo) {
        this.setLoginInfo(loginInfo);
        console.log(this.loginInfo);
      }
    },
    // 是否存在用户
    async existsUser(userName: string) {
      if (userName != "") {
        return await http.get<IMsg_Data<string>>({
          url: `/api/Register/ExistsUser?userName=${userName}`,
        });
      }
    },
    // 上传文件
    async uploadFile(fileList: any[]) {
      const formData = new FormData();
      fileList.forEach((file: any) => {
        formData.append("file", file.file);
      });

      const res = await http.post<IMsg_Data<{ file1: string; file2: string }>>({
        url: "/api/Register/UploadFile",
        data: formData,
      });

      if (res.result === ResultCode.ok) {
        this.setImgFiles(res.data.file1, res.data.file2);
      }
      return res;
    },
    // 新用户注册
    async regUser(params: { userName: string; pwd: string; email: string }) {
      const res = await http.post<IMsg_Data<string>>({
        url: "/api/Register/RegUser",
        data: params,
      });
      this.setToken(res.data);
      localStorage.setItem("token", res.data);
    },

    // 登录
    async login(params: IDTO_Login) {
      this.logOut();
      const res = await http.post<IMsg_Data<IDTO_LoginResult>>({
        url: "/api/Login/Login",
        data: params,
      });
      this.setLoginStatus(res.result);
      if (res.data) {
        this.setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
      }
      if (res.result == ResultCode.ok) {
        // 登录成功逻辑

        this.setLoginInfo(res.data);
        await this.getRegInfo(res.data.token);
        localStorage.setItem("loginInfo", JSON.stringify(res.data));
      } else if (res.result == ResultCode.err_data || res.result == ResultCode.wait || res.result == ResultCode.notavailable) {
        // 返回token 用于获取学习注册信息
        await this.getRegInfo(res.data.token);
      }
      return res;
    },

    // 创建或者重新提交学校申请资料
    async createSchoolInfo(params: IDTO_RegSchoolCreate) {
      const res = await http.post<IMsg_Data<number>>({
        url: "/api/Register/Create",
        data: params,
      });
      return res;
    },
    // 通过手机验证码更改密码
    async changePwd(params: IDTO_Login) {
      const res = await http.get<IMsg_Data<string>>({
        url: `/api/Register/ChangePwd?phone=${params.phone}&code=${params.code}&userName=${params.userName}&password=${params.password}`,
      });
      return res;
    },
    // 获取学校注册信息
    async getRegInfo(token: string) {
      if (token) {
        const ret = await http.get<IMsg_Data<ISchoolInfo>>({
          url: `/api/Register/GetRegInfo`,
          params: {
            token,
          },
        });
        if (ret.result === ResultCode.ok) {
          this.setSchoolInfo(ret.data);
        }
      }
    },
    // 获取省市区数组
    async getCity() {
      const res = await http.get<IMsg_Items<any>>({
        url: `/api/City/Get`,
      });
      if (res.result == ResultCode.ok) {
        this.city = res.items;
      }
    },
    // 获取验证码
    async sendSmsCode(userName: string, phoneNumber: string) {
      const res = await http.get<IMsg_Data<number>>({
        url: "/api/SmsCode/SendSmsCode",
        params: {
          userName,
          phoneNumber,
        },
      });
    },
    // 获取当前统计信息
    async getStatistics() {
      return await http.get<IMsg_Data<IStatistics>>({
        url: "/api/Home/Statistics",
      });
    },
  },
});

export default useLogin;
