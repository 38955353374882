import { App } from "vue";
import { Resize } from "./resize";

// 自定义指令集合
const directives = {
  Resize,
};
export default {
  install(app: App) {
    Object.keys(directives).forEach(key => {
      app.directive(key, directives[key as keyof typeof directives]);
    });
  },
};
