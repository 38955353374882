import Quill from "quill";
const ImageBlot = Quill.import("formats/image");
type TAValue = {
  url: string | null;
  width: string | null;
  height: string | null;
};
// 扩展Quill内置的image 使其能够插入图片时指定宽度
class AdjustableImgBlot extends ImageBlot {
  static blotName = "adjustImg";
  static tagName = "img";

  static create(value: TAValue): any {
    const node = super.create(value);
    node.setAttribute("src", ImageBlot.sanitize(value.url));

    node.onload = function () {
      // console.dir(node, node.naturalHeight);
      // console.log(node.naturalHeight, node.height);
      if (node.naturalHeight > node.height) {
        if (value.width != undefined) {
          console.log(node.height, node.naturalHeight, node.naturalWidth);
          console.log((Number(value.height) / node.naturalHeight) * node.naturalWidth);
          // 按高度等比缩放设置宽度 缩放比= 窗口高度/图片实际高度 = 窗口宽度/图片实际宽度
          node.setAttribute("width", (Number(value.height) / node.naturalHeight) * node.naturalWidth + "px");
        }
        // if (value.height != undefined) {
        //   node.setAttribute("height", value.height);
        // }
      }
    };
    return node;
  }
  static value(node: HTMLElement): TAValue {
    return {
      url: node.getAttribute("src"),
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
    };
  }
}
export default AdjustableImgBlot;
