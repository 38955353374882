/** 显示一个需要重新登录的提示框,点击后跳回到登录界面 */ /*不要了,直接跳到登录界面就好*/
<script lang="ts" setup>
import { ref, computed } from "vue";
import { ElButton, ElDialog } from "element-plus";
import { useRouter } from "vue-router";
import msgbox from "@/store/main/msgbox";

const router = useRouter();

const visible = computed({
  get() {
    return msgbox().needlogin;
  },
  set(val) {
    msgbox().needlogin = val;
  },
});

const closedlg = () => {
  msgbox().needlogin = false;
  router.push({
    name: "login",
    query: {
      redirect_url: "当前路径",
    },
  });
};
</script>
<template>
  <el-dialog v-model="visible" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <h4 :id="titleId" :class="titleClass">登录信息已经过期</h4>
      </div>
    </template>
    <el-button type="danger" @click="closedlg()">
      <el-icon class="el-icon--left"></el-icon>
      重新登录
    </el-button>
  </el-dialog>
</template>
