/**
 * 用于系统api调用的默认http请求对象
 *
 */

import httpRequest from "../net/http/httpRequest";

import { BASE_URL, TIMEOUT } from "../config/config";
const http = new httpRequest({
  baseURL: BASE_URL,
  // baseURL: "", // mock测试
  timeout: TIMEOUT,
  validateStatus(status) {
    //只有401和403才外部进行处理,其他都交给httpRequest本身处理
    return status != 401 && status != 403; // status >= 200 && status < 300 ; // default
  },
});

export default http;
