export const ResultCode = {
    ok: "ok",
    pending: "pending",
    working: "working",
    fail: "fail",
    notfound: "notfound",

    notmodified: "notmodified",
    modified: "modified",
    exists: "exists",
    open: "open",
    closed: "closed",
    err_name: "err_name",
    err_pwd: "err_pwd",
    err_timeout: "err_timeout",
    err_data: "err_data",
    err_param: "err_param",
    err_io: "err_io",
    err_db: "err_db",
    err_net: "err_net",
    err_ver: "err_ver",
    err_limit: "err_limit",
    err_serve: "err_serve",
    nologin: "nologin",
    locked: "locked",
    nolock: "nolock",
    inuse: "inuse",
    cancel: "cancel",
    noprocess: "noprocess",
    notavailable: "notavailable",
    err_timeup: "err_timeup",
    notsupported: "notsupported",
    wait: "wait",
    err_code: "err_code",
    Msg: {
        ok: "成功",
        pending: "等待完成",
        working: "正在处理",
        fail: "失败",
        notfound: "未能找到对应的信息",

        notmodified: "没有发生改变",
        modified: "已改变",
        exists: "已存在",
        open: "已开放",
        closed: "已关闭",
        err_name: "名称存在错误",
        err_pwd: "密码存在错误",
        err_timeout: "超时",
        err_data: "错误的数据",
        err_param: "错误的参数",
        err_io: "文件读写错误",
        err_db: "数据库访问错误",
        err_net: "网络访问错误",
        err_ver: "版本不匹配",
        err_limit: "超出上线",
        err_serve: "服务器出现异常",
        nologin: "尚未登录",
        locked: "处在锁定状态",
        nolock: "未锁定",
        inuse: "正在使用中",
        cance: "取消",
        noprocess: "未处理",
        notavailable: "不可用",
        err_timeup: "时间用完",
        notsupported: "不支持",
        wait: "等待",
        err_code: "错误的验证码"
    },
    ToString: function (code: any) {
        return this.Msg[code as keyof typeof this.Msg];
    }
}
