/**
 * 全局配置
 */
let BASE_URL = "";
let BASE_NAME = "";
//api调用超时时间
let TIMEOUT = 10000;
if (import.meta.env.DEV) {
  BASE_URL = "/api";
  // BASE_URL = ''
} else if (import.meta.env.PROD) {
  BASE_URL = "";
}
const QUILL_FONT = ["SimSun", "SimHei", "Microsoft-YaHei", "KaiTi", "FangSong", "Arial", "Times-New-Roman", "sans-serif"];
const QUILL_TOOLBAR_OPTIONS = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: ["SimSun", "SimHei", "Microsoft-YaHei", "KaiTi", "FangSong", "Arial", "Times-New-Roman", "sans-serif"] }],
  [{ align: [] }],
  ["clean"],
  ["link", "image", "video"],
];
export { BASE_URL, TIMEOUT, QUILL_TOOLBAR_OPTIONS, QUILL_FONT };
