/**
 * type
 * 1: 一级菜单(submenu 包含 menuitem)
 * 2：二级菜单
 *
 * 4: 一级菜单(menuitem 不包含submenu)
 *
 *
 */

// 管理员

export const menuConfig_admin = [
  {
    key: "schoolBasicInfo",
    name: "学校基本信息",
    icon: "school",
    url: "/main/manage/schoolBasicInfo",
    type: 4,
  },
  {
    key: "summary",
    name: "首页",
    icon: "house",
    url: "/main/summary",
    type: 4,
  },
  {
    key: "11",
    name: "学校组织管理",
    icon: "Cpu",
    children: [
      {
        key: "teacherManage",
        name: "授课教师管理",
        url: "/main/manage/orgManage/teacherManage",
        type: 2,
      },
      {
        key: "classManage",
        name: "班级与学生管理",
        url: "/main/manage/orgManage/classManage",
        type: 2,
      },
      {
        key: "interestGroup",
        name: "兴趣小组浏览",
        url: "/main/manage/orgManage/interestGroup",
        type: 2,
      },
      // {
      //   key: "orgManage",
      //   name: "组织管理",
      //   children: [
      //     {
      //       key: "teacherManage",
      //       name: "教师管理",
      //       url: "/main/manage/orgManage/teacherManage",
      //       type: 2,
      //     },
      //     {
      //       key: "classManage",
      //       name: "班级与学生管理",
      //       url: "/main/manage/orgManage/classManage",
      //       type: 2,
      //     },
      //     {
      //       key: "interestGroup",
      //       name: "兴趣小组浏览",
      //       url: "/main/manage/orgManage/interestGroup",
      //       type: 2,
      //     },
      //   ],
      //   type: 3,
      // },
      // {
      //   key: "teachingMaterials",
      //   name: "校教学资源管理",

      //   type: 3,
      // },
      // {
      //   key: "mineResource",
      //   name: "我的资源",
      //   children: [
      //     {
      //       key: "mineCourseBrowse",
      //       name: "课程浏览",
      //       url: "/main/manage/mineCourseBrowse",
      //       type: 2,
      //     },
      //     {
      //       key: "mineQuestionBrowse",
      //       name: "题库浏览",
      //       url: "/main/manage/mineQuestionBrowse",
      //       type: 2,
      //     },
      //     {
      //       key: "mineExamBrowse",
      //       name: "考卷浏览",
      //       url: "/main/manage/mineExamBrowse",
      //       type: 2,
      //     },
      //   ],
      //   type: 3,
      // },
    ],
    type: 1,
  },
  {
    key: "12",
    name: "学校教学资源管理",
    children: [
      {
        key: "courseBrowse",
        name: "课程浏览",
        url: "/main/manage/teachingMaterials/courseBrowse",
        type: 2,
      },
      {
        key: "questionBrowse",
        name: "题库浏览",
        url: "/main/manage/teachingMaterials/questionBrowse",
        type: 2,
      },
      {
        key: "examBrowse",
        name: "考卷浏览",
        url: "/main/manage/teachingMaterials/examBrowse",
        type: 2,
      },
    ],
    type: 1,
    icon: "Reading",
  },
  {
    key: "authManage",
    name: "权限管理",
    icon: "Tools",
    url: "/main/manage/authManage",
    type: 4,
  },
  {
    key: "13",
    name: "公共市场",
    children: [],
    type: 4,
    icon: "Box",
  },
  // {
  //   key: "3",
  //   name: "我的课堂",
  //   icon: "DataBoard",
  //   children: [
  //     {
  //       key: "classroom",
  //       name: "我的课堂",
  //       url: "/main/classroom/classroom",
  //       type: 2,
  //     },
  //   ],
  //   type: 1,
  // },
  // {
  //   key: "4",
  //   name: "团队",
  //   icon: "Avatar",
  //   children: [
  //     {
  //       key: "coteam",
  //       name: "我的团队",
  //       url: "/main/coteam/coteam",
  //       type: 2,
  //     },
  //   ],
  //   type: 1,
  // },
  // {
  //   key: "5",
  //   name: "个人设置",
  //   icon: "User",
  //   children: [],
  //   type: 1,
  // },
];
export const menuConfig_user = [
  {
    key: "summary",
    name: "首页",
    icon: "house",
    url: "/main/summary",
    type: 4,
  },
  {
    key: "1",
    name: "学校教学资源管理",
    children: [
      {
        key: "courseBrowse",
        name: "课程浏览",
        url: "/main/manage/teachingMaterials/courseBrowse",
        type: 2,
      },
      {
        key: "questionBrowse",
        name: "题库浏览",
        url: "/main/manage/teachingMaterials/questionBrowse",
        type: 2,
      },
      {
        key: "examBrowse",
        name: "考卷浏览",
        url: "/main/manage/teachingMaterials/examBrowse",
        type: 2,
      },
    ],
    type: 1,
    icon: "Reading",
  },
  {
    key: "mineResource",
    name: "我的资源",
    children: [
      {
        key: "mineCourseBrowse",
        name: "课程浏览",
        url: "/main/manage/mineCourseBrowse",
        type: 2,
      },
      {
        key: "mineQuestionBrowse",
        name: "题库浏览",
        url: "/main/manage/mineQuestionBrowse",
        type: 2,
      },
      {
        key: "mineExamBrowse",
        name: "考卷浏览",
        url: "/main/manage/mineExamBrowse",
        type: 2,
      },
    ],
    icon: "Folder",
    type: 1,
  },
  {
    key: "2",
    name: "公共市场",
    children: [],
    type: 1,
    icon: "Box",
  },
  {
    key: "6",
    name: "我的班级",
    icon: "Notebook",
    children: [
      {
        key: "myclass",
        name: "我的班级",
        url: "/main/myclass/myclass",
        type: 2,
      },
      {
        key: "myInterestGroup",
        name: "我的兴趣小组",
        url: "/main/my-interest-group/my-interest-group",
        type: 2,
      },
    ],
    type: 1,
  },
  {
    key: "3",
    name: "我的课堂",
    icon: "DataBoard",
    children: [
      {
        key: "classroom",
        name: "我的课堂",
        url: "/main/classroom/classroom",
        type: 2,
      },
    ],
    type: 1,
  },
  {
    key: "4",
    name: "我的团队",
    icon: "Avatar",
    children: [
      {
        key: "coteam",
        name: "我的团队",
        url: "/main/coteam/coteam",
        type: 2,
      },
    ],
    type: 1,
  },
  // {
  //   key: "5",
  //   name: "个人设置",
  //   icon: "User",
  //   children: [],
  //   type: 1,
  // },
];
